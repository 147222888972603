import { useEffect, useState } from 'react';
import './css/course-template.css';
import { useNavigate, useParams } from "react-router-dom"
import axios from 'axios';
import { endpoint } from '../Services/Endpoint';
import ICategory from '../interfaces/ICategory';
import ISection from '../interfaces/ISection';
import { CaseInterface } from '../interfaces/prev/CaseInterface';
import { onAuthStateChanged, User} from 'firebase/auth'
import { Timestamp } from "firebase/firestore";
import { auth } from '../firebase-config';
import { IsAdmin } from '../Services/IsAdmin';
import IUser, { ISubscription } from '../interfaces/IUser';
import ICard from '../interfaces/ICard';
import Loading from '../components/Loading';

import { Modal } from "../components/Modal";
import { useTranslation } from 'react-i18next';
import { SimulatorModal, OpenCase } from '../components/layout/SimulatorModal';

export default function CourseTemplate(){
    const {t} = useTranslation();
    const { id } = useParams<string>();
    const [section, setSection] = useState<ISection|any>();
    const [categories, setCategories] = useState<ICategory[]|any[]>([]);
    const [cases, setCases] = useState<CaseInterface[]|any[]>([]);
    const [user, setUser] = useState<User|null>(null);
    const [pricing, setPricing] = useState<any[]>([])
    const [userData, setUserData] = useState<IUser>();
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState(false); 
    const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
    const [isOpenSelectSimulatorModal, setIsOpenSelectSimulatorModal] = useState(false);
    const [selectedCase, setSelectedCase] = useState("");

    const navigate = useNavigate();

    const onLoadPage = async() => {
        await axios.get(`${endpoint}/courses/sections`).then(response => { setSection(response.data.filter((x:any) => {return x.id === id})[0]); });
        await axios.get(`${endpoint}/courses/categories`).then(response => { setCategories(response?.data.filter((x:any) => { return x.section == id })); });
        await axios.get(`${endpoint}/courses/cases`).then(response => { setCases(response.data); });
        await axios.get(`${endpoint}/pricing`).then(response => setPricing(response.data));
        onAuthStateChanged(auth, (user)=>{
            if (user){
                setUser(user);
                IsAdmin().then(response => setIsAdmin(response))
                axios.get(`${endpoint}/users/${user.uid}`).then((response) => {setUserData(response.data)});
            }
             
            setLoading(false);
        });
    }
    const toggleSubscriptionModal = ()=> setIsOpenSubscriptionModal(!isOpenSubscriptionModal);
    const toggleLoginModal = ()=> setIsOpenLoginModal(!isOpenLoginModal);
    
    const openCase = async (_case: CaseInterface) => {
        if(typeof user?.uid === 'undefined'){
            setIsOpenLoginModal(true);
        } else if(typeof userData !== 'undefined') {
            let pricingRequired:ICard[] = pricing.filter((x:any) => { return x?.sections?.includes(id) });
            let subscriptionsActive:ISubscription[]|any = userData?.subscriptions?.filter((x) => x?.next_billing_time.seconds > Timestamp.now().seconds);
            let canCourse = userData?.isAdmin || userData?.isPremium || isSubscribed(pricingRequired, subscriptionsActive) || isLearning();

            if(canCourse){
                //setSelectedCase(_case?.id);
                //window.open(`fuve://?userid=${user?.uid}&caseid=${_case?.id}&language=${lang()}`, '_blank');
                OpenCase(user?.uid, _case?.id);
                //setIsOpenSelectSimulatorModal(true);
            } else {
                setIsOpenSubscriptionModal(true);
            }
        } else {
            setLoading(true);
            axios.get(`${endpoint}/users/${user.uid}`).then((response) => {setUserData(response.data); setLoading(false);}).catch((error) => console.error(error));
        }
    }
    const isCaseDone = (_case: CaseInterface) => {
        return userData?.progress?.includes(_case.id);
    }
    const isSubscribed = (cards:ICard[], subscriptions:ISubscription[]) => {
        if(typeof cards === "undefined" || typeof subscriptions === "undefined")
            return false;
        
        for (const card of cards) {
            for (const subscription of subscriptions) {
                if (card.id === subscription.product_id) {
                    return true;
                }
            }
        }
        return false;
    }
    const isLearning = () => {
        return userData?.isLearning && section.learning && userData?.learningDate.seconds > Timestamp.now().seconds;
    }
    const goToPricing = ()=> {
        navigate('/pricing', { replace: true });
    }
    
    const goToLogin = ()=> {
        navigate('/login', { replace: true });
    }
    const lang = () => localStorage.getItem("lan") ?? "en"; 

    useEffect(()=>{ onLoadPage(); }, []);

    if(loading) return <Loading/> 
    return (
        <main className='course-template'>
            <div className='head'>
                <div className="head-container">
                    <img src={section?.image ?? "/favicon.png"} alt="" />
                    <div> <span> { section?.title[lang()] ?? section.title["es"]  } </span> </div>
                </div>
            </div>
            {
                categories.map((x:any) => (
                    cases?.filter((y)=> {return y.category === x.id && (y.visibility || isAdmin) }).length > 0 && <div className='categories'>
                        <div className='category-data' id={x.id}> <img src={x.image ?? "/favicon.ico" } alt="" /> <span>{x.title[lang()] ?? x.title["es"]}</span> </div>
                        <div className='courses-carousel'>
                            {
                                cases?.filter((y)=> {return y.category === x.id && (y.visibility || isAdmin) }).map((z:CaseInterface|any) => (
                                    <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/default-case.png'})`}} className="case-card" onClick={()=> {openCase(z)}}>
                                        <div className='case-content'>
                                            { !z.visibility && <span>Admin</span> }
                                            <div className='case-data'>
                                                <span className='case-title'>{ isCaseDone(z) ? (z?.hiddenTitle[lang()] ?? z.hiddenTitle["es"]) : (z?.title[lang()] ?? z.title["es"])}</span>
                                                <span className='case-description'>{ z.descriptionPreview[lang()] ?? z.descriptionPreview["es"] }</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
            <Modal title= {t("Modals.Subscription.Title")}  isOpen={isOpenSubscriptionModal} onClose={toggleSubscriptionModal}>
                <div className="modal-content">
                    <span>{t("Modals.Subscription.Description")}</span>
                    <input type='button' value={t("Modals.Subscription.Button")} onClick={goToPricing}/>
                </div>
            </Modal>
            <Modal title={t("Modals.Login.Title")} isOpen={isOpenLoginModal} onClose={toggleLoginModal}>
                <div className="modal-content">
                    <span>{t("Modals.Login.Description")}</span>
                    <input type='button' value={t("Modals.Login.Button")} onClick={goToLogin}/>
                </div>
            </Modal>
            <SimulatorModal _case = {selectedCase} lang={lang()} userId={user?.uid} isOpenSelectSimulatorModal = {isOpenSelectSimulatorModal} onCloseModal={()=>{setIsOpenSelectSimulatorModal(false)}}/>
        
        </main>
    )
}