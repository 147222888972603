import React, { useEffect, useState } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from "axios";
import { endpoint } from "../../Services/Endpoint";
import { Modal } from "../Modal";

interface Props {
  planId:string;
  userId:string|undefined;
}

const SubscriptionComponent: React.FC<Props> = ({planId, userId}) => {
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  const [isOpenFailModal, setIsOpenFailModal] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  return (
    <div>
        <h1>Suscríbete</h1>
        
          <PayPalButtons fundingSource={undefined} createSubscription={(data, actions) => { return actions.subscription.create({ plan_id: planId }); }} 
            onApprove={async(data, actions) => {
              axios.put(`${endpoint}/paypal-register/${userId}`, { date: Date.now(), order: data })
              .then(response => setIsOpenApproveModal(true))
              .catch(err => { setIsOpenFailModal(true); console.error(err) });
            }}
            onCancel={(data, actions)=> setIsOpenCancelModal(true) }
            onError={(err) => { console.error("Error durante la suscripción:", err); }}
          />
        
      <Modal title={"Suscripcion activa"} isOpen={isOpenApproveModal} onClose={() => setIsOpenApproveModal(false)}>
          <div className="modal-content">
              <span>{"Se ha activado tu suscripcion a FUVE."}</span>
              <input type='button' value={"Aceptar"} onClick={() => setIsOpenApproveModal(false)}/>
          </div>
      </Modal>
      <Modal title={"Ha ocurrido un error"} isOpen={isOpenFailModal} onClose={() => setIsOpenFailModal(false)}>
          <div className="modal-content">
              <span>{"Se realizo el pago de la suscripcion pero al momento de registrarlo en la base de datos hubo un problema.\nContacta con el administrador"}</span>
              <input type='button' value={"Contactar"} onClick={(e) => { window.location.href = "mailto:jrobles@medicinafetalmexico.com"; e.preventDefault(); } }/>
          </div>
      </Modal>
      <Modal title={"Pago no procesado"} isOpen={isOpenCancelModal} onClose={() => setIsOpenCancelModal(false)}>
          <div className="modal-content">
              <span>{"No se pudo procesar el pago para la suscripcion"}</span>
              <input type='button' value={"Aceptar"} onClick={() => setIsOpenCancelModal(false)}/>
          </div>
      </Modal>
    </div>
    
  );
};

export default SubscriptionComponent;
