import { useEffect, useState } from "react";
import "./css/suscriptions.css";
import axios from "axios";
import { endpoint } from "../Services/Endpoint";
import ISection from "../interfaces/ISection";
import ICategory from "../interfaces/ICategory";
import { CaseInterface } from "../interfaces/prev/CaseInterface";

interface ISuscriptionData {
    title: string;
    id:string;
    cases: string[];
}

export default function Suscriptions() {
    const [allSections, setAllSections] = useState<ISection[]>([]);
    const [allCategories, setAllCategories] = useState<ICategory[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<ICategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [allCases, setAllCases] = useState<CaseInterface[]>([]);
    const [filteredCases, setFilteredCases] = useState<CaseInterface[]>([]);
    const [selectedCase, setSelectedCase] = useState<CaseInterface>();

    const [data, setData] = useState<ISuscriptionData[]>([]);
    const [showModal, setShowModal] = useState<{sectionId?:string, active:boolean}>({ sectionId:undefined, active: false});

    const onLoadPage = () => {
        axios.get(`${endpoint}/courses/sections`)
        .then((res) => setAllSections(res.data))
        .catch((err) => console.error(err));
        axios.get(`${endpoint}/courses/categories`)
        .then((res) => {
            setAllCategories(res.data);
            setFilteredCategories(res.data);
        }).catch((err) => console.error(err));
        axios
        .get(`${endpoint}/courses/cases`)
        .then((res) => {
            setAllCases(res.data);
            setFilteredCases(res.data);
        })
        .catch((err) => console.error(err));
        axios.get(`${endpoint}/get-cases`).then(res => setData(res.data.data)).catch(err => console.error(err));
    };
    const saveData = () => {
        axios.put(`${endpoint}/add-case`, data).then(res => console.log(res.data)).catch(err => console.error(err));
    }
    const addCase = () => {
        if (typeof selectedCase === "undefined") return;

        const prevData = [...data];
        data?.map(x => {
            if(x.id === showModal.sectionId){
                if(!x.cases.includes(selectedCase.id)){
                    x.cases.push(selectedCase.id);
                    
                    setData(prevData);
                } else {
                    console.log("Ya incluye este caso xd");
                    return;
                }
            }
        });
        setShowModal({sectionId:undefined, active:false});
    };
    const onChangeTitle = (sectionId:string, value:any) => {
        const prevData:any = [...data];
        data?.map(x => {
            if(x.id === sectionId)
                x.title = value;
        });
        setData(prevData);
    }
    const deleteCase = (sectionId:string, caseId: string) => {
        const prevData = [...data];
        data?.map(x => {
            if(x.id === sectionId)
                x.cases = x.cases.filter(x => x !== caseId);
        });       
        setData(prevData);
    };

    const filterCategories = (sectionId: string) => {
        setFilteredCategories(allCategories.filter((x) => x.section === sectionId || sectionId === "all"));
    };
    const filterCases = (categoryId: string) => {
        setFilteredCases(allCases.filter((x) => x.category === categoryId || (categoryId === "all" && filteredCategories.some((y) => y.id === x.category))));
        setSelectedCategory(categoryId);
    };
    const idToCase = (id: string): CaseInterface | undefined => allCases.find((x) => x.id === id);

    const addSection = () => {
        let uuid = GenerateUUID();
        setData((prevData) => Array.isArray(prevData) ? [...prevData, { cases: [], title: "Nueva Seccion", id: uuid }] : [{ cases: [], title: "Nueva Seccion", id: uuid }]);
    }
    const deleteSection = (sectionId:string) => setData(data.filter(x => x.id !== sectionId));
    const GenerateUUID = () => "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>(+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16));

    useEffect(() => filterCases("all"), [filteredCategories]);
    useEffect(() => setSelectedCase(filteredCases[0]), [filteredCases]);
    useEffect(onLoadPage, []);

    return (
        <div className="suscriptions-page">
            {<div className="suscriptions-sections">
                {data?.map((x) => (
                <div className="suscriptions-section">
                    <div className="section-header">
                        <div>
                            <input type="text" value={x.title} onChange={e=> {onChangeTitle(x.id, e.target.value)}} />
                        </div>
                        <img src={process.env.PUBLIC_URL + "/delete-white.png"} onClick={()=>{deleteSection(x.id)}} title="Eliminar"/>
                    </div>
                    <div className="suscriptions-cases">
                    <div className="suscriptions-case" onClick={_ => { setShowModal({sectionId:x.id, active: true}); filterCategories("all"); }} >
                        <img src={process.env.PUBLIC_URL + "/add.png"} />
                    </div>
                    { x.cases.map(y => idToCase(y)).map((z) => (
                        <div className="suscriptions-case">
                        <img src={process.env.PUBLIC_URL + "/favicon.png"} />
                        <img src={process.env.PUBLIC_URL + "/delete-white.png"} onClick={ _ => { deleteCase(x.id, z?.id ?? "") } } title="Eliminar"/>
                        <div>
                            <span>{z?.title.es}</span>
                            <span>{z?.descriptionPreview.es}</span>
                        </div>
                        </div>
                    )) }
                    </div>
                </div>
                ))}
                <input className="button" type="button" value="Agregar nueva seccion" onClick={()=>{addSection();}} />
            </div> }
            <input className="button button-floating" type="button" value={"Guardar"} onClick={saveData} />
            { showModal.active && (<div className="add-modal-container" onClick={ _ => setShowModal({sectionId:undefined, active:false}) }>
                <div className="add-modal" onClick={e => e.stopPropagation()}>
                    <span>Agregar Caso</span>
                    <div className="selects">
                    <select onChange={(e) => filterCategories(e.target.value)}>
                        <option value="all">Todas las secciones</option>
                        { allSections?.map(section => <option value={section.id}>{section.title.es}</option> ) }
                    </select>

                    <select value={selectedCategory} onChange={e => filterCases(e.target.value)} >
                        <option value="all">Todas las categorias</option>
                        { filteredCategories?.map(categories => <option value={categories.id}> { categories.title.es } </option> )}
                    </select>

                    <select value={selectedCase?.id} onChange={e => setSelectedCase(filteredCases.find(x => x.id === e.target.value)) }>
                        { filteredCases.map(_case => <option value={_case.id}>{_case.title.es}</option>)}
                    </select>
                    </div>

                    <div className="buttons">
                    <input className="blue" type="button" value="Agregar" onClick={ addCase } />
                    <input type="button" value="Cancelar" onClick={_ => setShowModal({sectionId:undefined, active:false})} />
                    </div>
                </div>
            </div>
        ) }
        </div>
    );
}