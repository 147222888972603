import { Modal } from "../Modal"
import { UnityVersion } from "../../Services/UnityVersion";
import { CustomProtocolManager } from "../../Services/CustomProtocolManager";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SimulatorModalProps {
    userId:string|any;
    _case: string;
    lang: string|any;
    isOpenSelectSimulatorModal: boolean;
    onCloseModal: () => void;
  }

  
export const OpenCase = (userId: string, _case:string) =>{
    window.open(`fuve://?userid=${userId}&caseid=${_case}&language=${localStorage.getItem("lan") ?? "en"}`, '_blank');
}
export const SimulatorModal: React.FC<SimulatorModalProps> = ( { userId, _case, lang, isOpenSelectSimulatorModal, onCloseModal }) => {
    const [rememberSelection, setRememberSelection] = useState(false);
    
    const { t } = useTranslation();

    const simulator = "simulator";
    const openCaseBrowser = () => {
        UnityVersion().then((unityVersion) => {});
        
        if(rememberSelection){
            localStorage.setItem(simulator, "browser");
        }
        
        onCloseModal();
    }
    const openCaseDesktop = ()=>{
        let urlData = `?userid=${userId}&caseid=${_case}&language=${lang}`;
        CustomProtocolManager.openCase(urlData);
        
        if(rememberSelection){
            localStorage.setItem(simulator, "desktop");
        }

        onCloseModal();
    }

    useEffect(()=>{
        if(isOpenSelectSimulatorModal){            
            if(localStorage.getItem(simulator) === 'browser'){
                openCaseBrowser();
                onCloseModal();
            } else if(localStorage.getItem(simulator) === 'desktop'){
                openCaseDesktop();
                onCloseModal();
            }
        }
        
    }, [isOpenSelectSimulatorModal]);

    return(
        <>
            <Modal title={t("Modals.WhichVersion.Title")} isOpen={isOpenSelectSimulatorModal} onClose={onCloseModal}>
                <div className="modal-content">
                    <input type='button' value={t("Modals.WhichVersion.DesktopVersion")} onClick={openCaseDesktop}/>
                    <input type='button' value={t("Modals.WhichVersion.BrowserVersion")} onClick={openCaseBrowser}/>
                    <div className='row m-v-10'>
                        <label className="switch">
                            <input type="checkbox" checked = {rememberSelection} onChange={(e) => setRememberSelection(e.target.checked)}/>
                            <span className="slider round"/>
                        </label>
                        <span className='center m-h-10'>{t("Modals.WhichVersion.Remember")}</span>
                    </div>
                </div>
            </Modal>
        </>
    )
}