import './styles/header.css'

import { useState, useEffect } from 'react'
import { auth } from '../../firebase-config'
import { signOut, onAuthStateChanged, User } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { IsAdmin } from '../../Services/IsAdmin';
import i18n from '../../i18n/config'
import { OpenCase, SimulatorModal } from './SimulatorModal'
import { IsPartner } from '../../Services/IsPartner'
import { endpoint } from '../../Services/Endpoint'
import axios from 'axios'
import { IsLearning } from '../../Services/IsLearning'

export default function Header(){
    const { t } = useTranslation();
    
    const [user, setUser] = useState<User|null>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLearning, setIsLearning] = useState(false);
    const [isPartner, setIsPartner] = useState(false);
    const [language, setLanguage] = useState('es');
    const [demo, setDemo] = useState("");
    
    const navigate = useNavigate();
    let from = '/';

    const onLoadPage = async() => {
        onAuthStateChanged(auth, async(user)=>{
            if (user){
                setUser(user);
                await IsAdmin().then(response => setIsAdmin(response));
                await IsPartner().then(response => setIsPartner(response));
                await IsLearning().then(response => setIsLearning(response));
            }
        });
        axios.get(`${endpoint}/getdemo`).then((response:any) => setDemo(response.data.id)).catch(err => console.error(err));

        setLanguage(window.localStorage.getItem("lan") ?? "en");    
        i18n.changeLanguage(window.localStorage.getItem("lan") ?? "en"); 
    } 
    const changeLanguage = (lan:string) => {
        setLanguage(lan);
        i18n.changeLanguage(lan);
        window.localStorage.setItem("lan", lan);
    }
    const logout = async()=>{
        await signOut(auth);
        navigate(from, { replace: true });
        window.location.reload();
    };
    const openCase = ()=>{
        if(user){
            //window.open(`fuve://?userid=${user?.uid}&caseid=${demo}&language=${language}`, '_blank');
            OpenCase(user?.uid, demo);
        } else 
            window.open('/login');
    }
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => { setIsOpen(!isOpen); };
    useEffect(()=> {onLoadPage()}, []);
    
    return(
        <>
            <div className='header'>
                <a href="/" className="logo"> 
                    <img src={"/logo.png"}/>
                </a>
                <div className='categories'>
                    <a href="/" className="nav-link">{t('Header.Home')}</a>
                    <a href="/courses" className="nav-link">{t('Header.Courses')}</a>
                    { (isLearning || isAdmin) && <a href="/fuve-learning" className="nav-link">{"FUVE Learning" }</a> }
                    <a onClick={()=> {openCase()}} className="nav-link openCase-Link">{t('Header.MakeADemo')}</a>
                    <a href="/pricing" className="nav-link">{t('Header.Prices')}</a>
                    <a href="/faqs" className="nav-link">FAQs</a>
                    { isAdmin && <a href="/admin" className="nav-link">{t('Header.Admin')}</a> }
                    {/* isPartner && <a href="/partner" className="nav-link">{t('Partner.Header')}</a> */}
                </div>
                <div className="settings">
                    <img className='language-icon' src={ `/${language}.png`}/>
                    <select id="" onChange={(e)=> { changeLanguage(e.target.value) }} value={window.localStorage.getItem("lan") ?? "en"}>
                        <option value="es">Español</option>
                        <option value="en">English</option>
                        <option value="fr">Français</option>
                        <option value="ar">عرب</option>
                        <option value="pt">Português</option>
                        <option value="zh-CN">中国人</option>
                        <option value="ru">Русский</option>
                    </select>
                    <div className="account">
                        { (user != null) && <>
                            <img src={ user.photoURL ?? process.env.PUBLIC_URL + 'accountIcon.png'}/>
                            <div className="dropdown">
                                <button onClick={toggleMenu} className="dropdown-toggle">
                                <img src={process.env.PUBLIC_URL + "/expand_icon.png"} />
                                </button>
                                {isOpen && (
                                    <ul className="dropdown-menu">
                                        <a href="/profile"><li>{t("Header.ViewProfile")}</li></a>
                                        { isAdmin && <a href="/admin" className=""> <li> {t('Header.Admin')} </li> </a> }
                                        { isPartner && <a href="/partner" className=""> <li> {t('Partner.Header')} </li> </a> }
                                        <li onClick={logout}>{t("Header.Logout")} </li>
                                    </ul>
                                )}
                                </div>
                        </> }
                        { (user == null) && <a href='/login'>{t("Header.Login")} </a> }
                    </div>
                </div>
            </div>
        </>
    )
}