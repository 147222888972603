import "./css/cases-manager.css"

export default function CasesManager() {
    return <main className="cases-manager">
        <div className="select-cases">
            <div className="select-field">
                <span>Select Section:</span>
                <select name="" id="">
                    <option value="">Todo</option>
                </select>
            </div>
            <div className="select-field">
                <span>Select Category:</span>
                <select name="" id="">
                    <option value="">Todo</option>
                </select>
            </div>
            <div className="select-field">
                <span>Select Case:</span>
                <select name="" id="">
                    <option value="">Test asd asd sa d sa</option>
                </select>
            </div>
        </div>
        <div className="cases-buttons">
            <div className="buttons">
                <input className="custom-button" type="button" value="Agregar Caso" />
                <input className="custom-button red" type="button" value="Eliminar Caso" />
                <input className="custom-button green" type="button" value="Duplicar Caso" />
            </div>
            <div className="img-buttons">
                <img className="img-button" src={process.env.PUBLIC_URL + "/add-simple.png"} alt="" />
                <img className="img-button red" src={process.env.PUBLIC_URL + "/delete-white.png"} alt="" />
                <img className="img-button green" src={process.env.PUBLIC_URL + "/copy_icon.png"} alt="" />
            </div>
            <div className="language">
                <span>Idioma a traducir: </span>
                <select name="" id="">
                    <option value="es">Español</option>
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="ar">عرب</option>
                    <option value="pt">Português</option>
                    <option value="zh-CN">中国人</option>
                    <option value="ru">Русский</option>
                </select>
                <img className="img-button" src={process.env.PUBLIC_URL + "/Translate.png"} alt="" />
            </div>
            <div className="case-id">
                <span>Id del caso: </span>
                <a href="">uyNwr5m9l7tTTOW8hCWb</a>
                <img className="img-button" src={process.env.PUBLIC_URL + "/copy_icon.png"} alt="" />
            </div>
        </div>

        <br/>
        <span>Información y atributos</span>
        <div className="basic-information">
            <div className="basic">
                <p>Estado del curso: <span>Visible</span> </p>
                <div className="field">
                    <span>Titulo: </span>
                    <input type="text" placeholder="Introduce el titulo del caso" />
                </div>
                <div className="field">
                    <span>Sección: </span>
                    <select name="" id="">
                        <option value="">Seccion</option>
                    </select>
                </div>
                <div className="field">
                    <span>Categoria: </span>
                    <select name="" id="">
                        <option value="">Seccion</option>
                    </select>
                </div>
                <div className="field">
                    <span>Titulo Oculto: </span>
                    <input type="text" placeholder="Introduce el titulo oculto del caso"/>
                </div>
            </div>
            <div className="basic">
                <div className="field">
                    <span>Descripción corta: </span>
                    <input type="text" placeholder="Introduce la descripcion corta" />
                </div>
                <div className="field">
                    <span>Descripcion: </span>
                    <textarea name="" placeholder="Introduce la descripcion" id=""></textarea>
                </div>
                <div className="field">
                    <input className="custom-button" type="button" value="Seleccionar como demo" />
                    <input className="custom-button" type="button" value="Ocultar" />
                </div>
                
            </div>
        </div>

        <br />
        <div className="views">

            <div className="view">
                <div className="view-header">
                    <span>Vista #</span>
                    <p>Estado: <span>Visible</span></p>
                    <div className="buttons">
                        <img className="img-button red" src={process.env.PUBLIC_URL + "/delete-white.png"} />
                        <img className="img-button" src={process.env.PUBLIC_URL + "/drag.png"} />
                    </div>
                </div>
                <div className="view-body">
                    <div className="view-basic">
                        <div className="field">
                            <span>Titulo: </span>
                            <input type="text" placeholder="Introduce el titulo del caso" />
                        </div>
                        <div className="field">
                            <span>Transductor: </span>
                            <select name="" id="">
                                <option value="0">Abdominal</option>
                                <option value="1">Transvaginal</option>
                                <option value="2">Volumetrico</option>
                                <option value="3">Volumetrico v2</option>
                            </select>
                        </div>
                        <div className="field wrap">
                            <div className="field">
                                <span>Unity Key: </span>
                                <input type="text" placeholder="" />
                            </div>
                            <div className="field">
                                <span>Area %: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                        </div>
                        <div className="field wrap">
                            <div className="field">
                                <span>Fragmentos: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                            <div className="field">
                                <span>Frame Rate: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                        </div>
                        <div className="field wrap">
                            <div className="field">
                                <span>Ancho: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                            <div className="field">
                                <span>Alto: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                        </div>
                        <div className="field">
                            <input type="button" className="custom-button" value="Ocultar" />
                        </div>
                    </div>
                    <div className="view-basic">
                        <div className="field wrap">
                            <div className="field">
                                <span>Doppler Key: </span>
                                <input type="text" placeholder="" />
                            </div>
                            <div className="field">
                                <span>Area %: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                        </div>
                        <div className="field wrap">
                            <div className="field">
                                <span>Fragmentos: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                            <div className="field">
                                <span>Frame Rate: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                        </div>
                        <div className="field">
                            <span>FC: </span>
                            <input type="number" placeholder="" value={0} />
                        </div>
                        <div className="field wrap">
                            <div className="field">
                                <span>Tipo de curva</span>
                                <select name="" id="">
                                    <option value="">N/A</option>
                                </select>
                            </div>
                            <div className="field">
                                <span>Normalidad</span>
                                <select name="" id="">
                                    <option value="">N/A</option>
                                </select>
                            </div>
                            <div className="field">
                                <span>PS: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                            <div className="field">
                                <span>ED: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                        </div>
                        <div className="field wrap">
                            <div className="field">
                                <span>Tipo de curva</span>
                                <select name="" id="">
                                    <option value="">N/A</option>
                                </select>
                            </div>
                            <div className="field">
                                <span>Normalidad</span>
                                <select name="" id="">
                                    <option value="">N/A</option>
                                </select>
                            </div>
                            <div className="field">
                                <span>PS: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                            <div className="field">
                                <span>ED: </span>
                                <input type="number" placeholder="" value={0} />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </main>
}