import axios from 'axios';
import { useState, useEffect } from 'react'
import { endpoint } from '../Services/Endpoint';
import { useTranslation } from 'react-i18next';
export default function Sales(){
    const [sales, setSales] = useState([]);

    const {t} = useTranslation();

    const onLoadPage = () => {
        axios.get(`${endpoint}/getsales`).then(response => { setSales(response.data.sort((a:any, b:any) => a.created - b.created).reverse()); });
    }
    const timestampToDate = (timestamp:any) =>  new Date(timestamp).toDateString(); 

    useEffect(onLoadPage, [])

    return  (
    <main className="reports">
        <h1>{t("Sales.Title")}</h1>
        {
            sales.map((sale:any) => (
                <div className="report">
                    <div className="report-header">
                        <span>{ ((typeof(sale?.plan_name) === 'object' ) ? sale?.plan_name["es"] : sale?.plan_name) ?? "Titulo del reporte" }</span>
                    </div>
                    <div className="report-body" >
                        <span>{t("Sales.Email")} {sale?.email ?? ""}</span>
                        <span>{t("Sales.Telephone")} {sale?.contact ?? ""}</span>
                        <span>{t("Sales.Country")} {sale?.country ?? ""}</span>
                    </div>
                    <div className="report-footer">
                        <span>{t("Sales.Total")} ${sale?.total ?? ""} USD</span>
                        <span>{ timestampToDate(sale?.date ?? "")}</span>
                    </div>
                </div>
            ))
        }
    </main>
    )
}