import "./css/cases.css";
import { useState, useEffect } from "react";
import axios from 'axios'
import { endpoint } from "../Services/Endpoint";
import ICategory from "../interfaces/ICategory";
import { Answer, CaseInterface, Indication, Question, View } from "../interfaces/prev/CaseInterface";
import { Timestamp } from "firebase/firestore";
import {auth} from '../firebase-config'
import {onAuthStateChanged, User} from 'firebase/auth'
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import { Translator } from "../Services/Translate";
import { Option } from "react-multi-select-component";
import { OpenCase, SimulatorModal } from "../components/layout/SimulatorModal";

export default function Cases() {
    //#region useStates
    const {t} = useTranslation();
    const [cases, setCases] = useState<CaseInterface[]|any[]>([]);
    const [allCases, setAllCases] = useState<CaseInterface[]|any[]>([]);
    const [categories, setCategories] = useState<ICategory[]|any[]>([]);
    const [currentCategory, setCurrentCategory] = useState<string>("all");
    const [caseCategories, setCaseCategories] = useState<ICategory[]|any[]>([]);
    const [allCategories, setAllCategories] = useState<ICategory[]|any[]>([]);
    const [sections, setSections] = useState<string[]|any[]>([]);
    const [caseSections, setCaseSection] = useState<ICategory[]|any[]>([]);
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);
    const [translateLang, setTranslateLang] = useState("all");
    const [isOpenSelectSimulatorModal, setIsOpenSelectSimulatorModal] = useState(false);
    //#endregion

    let curveOptions:Option[] = [
        {label: "N/A", value: 0},
        {label: "Arteria umbilical", value: 1},
        {label: "Arteria Cerebral Media", value: 2},
        {label: "Vena umbilical", value: 3}, 
        {label: "Ductus venoso", value: 4}, 
        {label: "Arteria Uterina", value: 5}, 
        {label: "Arteria Intrapulmonar", value: 6}, 
        {label: "Istmo Aórtico", value: 7}, 
        {label: "TEI", value: 8}, 
        {label: "Arteria Pulmonar", value: 9},
        {label: "Vena Hepática", value: 10},
        {label: "Arteria Ilíaca", value: 11},
        {label: "Mitral", value: 12},
        {label: "Tricúspide", value: 13},
        {label: "CIV", value: 14},
        {label: "Vena Pulmonar", value: 15},
        {label: "Arteria Subclavia", value: 16},
        {label: "Conducto Arterioso", value: 17},
        {label: "Vena Esplénica", value: 18},
        {label: "Aorta", value: 19},
        {label: "Vena Cava", value: 20},
        {label: "Arteria", value: 21},
    ];
    let curveCategories = [
        { label:"Arteria umbilical", Curve: 1, Subcategories: ["Normal", "Intermitente", "Arterio Arterial", "Bigeminismo", "Extrasistole"] },
        { label:"Arteria Cerebral Media", Curve: 2, Subcategories: ["Normal"] },
        { label:"Vena umbilical", Curve: 3, Subcategories: ["Normal", "Pulsatil", "Movimientos respiratorios"] },
        { label:"Ductus venoso", Curve: 4, Subcategories: ["Normal", "DV/Vena Umbilical", "DV/Vena Hepatica", "Movimientos respiratorios"] },
        { label:"Arteria Uterina", Curve: 5, Subcategories: ["Normal", "Extrasistole"] },
        { label:"Arteria Intrapulmonar", Curve: 6, Subcategories: ["Normal", "Arteria/Vena", "Arteria/Vena Patologica 1", "Arteria/Vena Patologica 2"] },
        { label:"Istmo Aórtico", Curve: 7, Subcategories: ["Normal", "Anormal"] },
        { label:"TEI", Curve: 8, Subcategories: ["Normal", "Anormal"] },
        { label:"Arteria Pulmonar", Curve: 9, Subcategories: ["Normal"] },
        { label:"Vena Hepática", Curve: 10, Subcategories: ["Normal"] },
        { label:"Arteria Ilíaca", Curve: 11, Subcategories: ["Normal"] },
        { label:"Mitral", Curve: 12, Subcategories: ["Normal", "Regurgitacion"] },
        { label:"Tricúspide", Curve: 13, Subcategories: ["Normal", "Regurgitacion", "Regurgitacion severa"] },
        { label:"CIV", Curve: 14, Subcategories: ["Normal"] },
        { label:"Vena Pulmonar", Curve: 15, Subcategories: ["Normal"] },
        { label:"Arteria Subclavia", Curve: 16, Subcategories: ["Normal"] },
        { label:"Conducto Arterioso", Curve: 17, Subcategories: ["Normal"] },
        { label:"Vena Esplénica", Curve: 18, Subcategories: ["Normal"] },
        { label:"Aorta", Curve: 19, Subcategories: ["Normal"] },
        { label:"Vena Cava", Curve: 20, Subcategories: ["Normal"] },
        { label:"Arteria", Curve: 21, Subcategories: ["Normal"] },
    ]
 
    const sortedData = (data:any[]) => data.sort((a:any, b:any) => a.title.es.localeCompare(b.title.es));
    const onLoadPage = async () => {
        if(cases?.length === 0){
            try{
                const [sectionRes, casesRes, categoriesRes] = await Promise.all([
                    axios.get(`${endpoint}/courses/sections`),
                    axios.get(`${endpoint}/courses/cases`),
                    axios.get(`${endpoint}/courses/categories`)
                ])
                
                setSections(sortedData(sectionRes.data));
                setCaseSection(sortedData(sectionRes.data));
                setCategories(sortedData(categoriesRes.data));
                setCaseCategories(sortedData(categoriesRes.data));
                setAllCategories(sortedData(categoriesRes.data));
                setCases(sortedData(casesRes.data));
                setAllCases(casesRes.data);
                setCurrentData(casesRes.data[0]);
                
                onAuthStateChanged(auth, (user)=>{
                    if (user)
                        setUser(user);
                });
                
                setLoading(false);
            } catch(error) {
                console.error(error);
            } 
        }
    }
    //#region Case Data
    const [currentData, setCurrentData] = useState<CaseInterface|any>(
        {
            id: "", 
            thumbnail: "",
            created: { _seconds: 0, _nanoseconds: 0 },
            diagnostic: { en: "", es: "" },
            visibility: false,
            category: "",
            descriptionPreview: { en: "", es: "" },
            hiddenTitle: { en: "", es: "" },
            title: { en: "", es: "" },
            description: { en: "", es: "" },
            views: [ {
                    pHeight: 0,
                    thumbnail: "",
                    visibility: false,
                    unityKey: "",
                    speedMultiplier: 1,
                    width: 0,
                    fragments: 0,
                    pWidth: 0,
                    title: { en: "", es: "" },
                    referenceUrl: "",
                    height: 0,
                    dopplerSpeedMultiplier: 1,
                    dopplerKey: "",
                    pDepth:0
                },
            ],
            quest: {
                indications: [],
                questions: [ {
                        question: { en: "", es: "" },
                        correct: 0,
                        answers: [ { en: "", es: "" } ],
                        explanation: { en: "", es: "" }
                    }
                ]
            }
        }
    )
    const ChangeElement = (id:string) => { 
        if(id === '')
            return;
       
        const url = window.location.href; 
        window.location.href = `${url.substring(0, url.indexOf('#'))}#${id}`;
        setCurrentData(cases.find((x:any) => { return x.id === id } ));  
    }
    useEffect(()=>{        
        onChangeCaseSection(sectionByCategory(currentData?.category));
    }, [currentData]);
    
    const onChangeSectionFilter = async (e:any) =>{
        let filteredCategories:ICategory[] = allCategories.filter((x:ICategory) => x.section === e.target.value || e.target.value === "all");
        //setCurrentCategory("all");<--puede ser necesario v:

        if(filteredCategories?.length > 0){
            setCategories(sortedData(filteredCategories));
        } else{
            setCategories([]);
        }
    }
    useEffect(()=>{onChangeCategoryFilter("all");}, [categories]);
    const onChangeCategoryFilter = async (e:any) => {
            let filteredCases:CaseInterface[] = allCases.filter((x:CaseInterface) =>  x.category === e || (e === "all" && categories.map(x => {return x.id}).includes(x.category)));
            setCurrentCategory(e);
            if(filteredCases?.length > 0){
                setCases(sortedData(filteredCases));        
            } else {
                setCases([]);
            }
    }
    useEffect(()=>{setCurrentData(cases[0])}, [cases])
    const addNewCase = () => {
        let newCase = {
            thumbnail: "",
            created: Timestamp.now(),
            diagnostic: { en: "", es: "" },
            visibility: false,
            category: categories[0].id,
            descriptionPreview: { en: "", es: "" },
            hiddenTitle: { en: "", es: "" },
            title: { es: "Nuevo Caso", en: "New Case" }, 
            description: { en: "", es: "" },
            views: [],
            quest: {
                indications: [],
                questions: []
            }
        }
        axios.post(`${endpoint}/courses/cases`, newCase).then((response) => updateCases(response.data)).catch((error) => console.error(error));
    }
    const updateCase = ()=> { 
        axios.put(`${endpoint}/courses/cases&${currentData.id}`, currentData).then(()=> {
            let updatedCases = cases;
            let index = updatedCases.findIndex(x => x.id == currentData.id);

            if(index !== -1)
                updatedCases[index] = currentData;
            setCases(updatedCases);

            let updatedAllCases = allCases;
            index = updatedAllCases.findIndex(x => x.id == currentData.id);
            if(index !== -1)
                allCases[index] = currentData;
            setAllCases(updatedAllCases);
            alert("Saved");
        }).catch(error => console.error(error)); 
    }
    const deleteCase = () => { 
        const response = window.confirm(`¿Seguro que quieres eliminar el caso de '${currentData?.title.es}'?`);
        if(response){
            axios.delete(`${endpoint}/courses/cases&${currentData.id}`).then(()=> {window.location.reload()}).catch(error => console.error(error)); 
        }
    }
    const duplicateCase = ()=> { 
        const response = window.confirm(`¿Seguro que quieres duplicar el caso de \n'${currentData?.title.es} (${currentData?.id})'?`);
        if(response){
            let duplicatedCase:any = {
                thumbnail: "",
                created: currentData.created,
                diagnostic: currentData.diagnostic,
                visibility: currentData.visiblity,
                category: currentData.category,
                descriptionPreview: currentData.descriptionPreview,
                hiddenTitle: currentData.hiddenTitle,
                title: { ar: currentData.title.ar, es: currentData.title.es + ' (Copy)', en: currentData.title.en + ' (Copy)', fr: currentData.title.fr + ' (Copy)', pt: currentData.title.pt + ' (Copy)', ru: currentData.title.ru + ' (Copy)', "zh-CN": currentData.title["zh-CN"] + ' (Copy)'}, 
                description: currentData.description,
                views: currentData.views,
                quest: currentData.quest
            }
            delete duplicatedCase.id;
            axios.post(`${endpoint}/courses/cases`, duplicatedCase).then((caseResponse)=> {
                updateCases(caseResponse.data);
            }).catch((error) => console.error(error));
        }
    }
    const updateCases = (newCase:any) =>{
        setCases(sortedData([...cases, newCase]));
    }
    //#endregion

    //#region Basic Data
    const handleCurrentData = (name:string, value:any) => setCurrentData((currentData:any) => ({...currentData, ...{[name] : value}}));        
    const handleChangeMulti = (name:any, value:any) => {
        let updatedData:any = { ...currentData }
        updatedData[name][Lang()] = value;
        setCurrentData(updatedData);
    }
    //#endregion

    //#region Views
    const addNewView = () => {
        let index = currentData?.views?.length ? currentData.views?.length : 0;
        let newView:View = { visibility:false, title:{en:`View ${index + 1}`, es:`Vista ${index + 1}`, fr:`View: ${index + 1}`}, fragments:0, frameRate: 1,  pWidth:0, pHeight:0, pDepth:0, width:0, height:0, unityKey:"", speedMultiplier:0, dopplerKey:"", dopplerSpeedMultiplier:0, thumbnail:"", referenceUrl:"" };
        setCurrentData((prevData:any) => ({...prevData, views: [...prevData.views || [], newView]}))
    }
    const removeView = (view:View) => {
        let prevData = { ...currentData };
        prevData.views = prevData.views.filter((x:any) => x.title.es !== view.title.es)
        setCurrentData(prevData);
    }
    const handleViewCurrentData = (view:View, name:string, value:any, multi:boolean = false) => {
        let prevData = { ...currentData };
        prevData.views.forEach((v:View | any) => {
            if(v.title.es === view.title.es){
                if(multi)
                    v[name][Lang()] = value;
                else 
                    v[name] = value;
                return;
            }
        })
        setCurrentData(prevData)
    }
    const resetNormallity = (view:View, nNormallity:any) =>{
        let prevData = {...currentData};
        
        prevData.views.forEach((v:View | any)=> {
            if(v.title.es === view.title.es){
                v["normallity" + nNormallity] = 0;

            }
        })
        setCurrentData(prevData);
    }
    const enabledFields = (_curveType:any, _normallity:any) => {
        return typeof(_curveType) === 'undefined' || typeof(_normallity) === 'undefined' || _normallity == 0
    }
    //#endregion
    
    //#region Questions 
    const addQuestion = ()=> {
        let index = (currentData.quest.questions?.length ?? 0) + 1;
        let prevData = { ...currentData };
        prevData.quest.questions.push({ question:{es:`¿Pregunta ${index}?`, en:`question ${index}?`}, explanation:{es: "", en:""}, correct: 0, answers:[]});
        setCurrentData(prevData);
    }
    const removeQuestion = (question:Question) => {
        let prevData = { ...currentData };
        prevData.quest.questions = prevData.quest.questions.filter((x:Question) => x.question.es !== question.question.es);
        setCurrentData(prevData);
    }
    const handleQuestionCurrentData = (question:Question, name:string, value:any) => {
        let prevData = {...currentData};

        prevData.quest.questions.forEach((q:any) => {
            if(q.question.es === question.question.es)
                q[name][Lang()] = value;
        });
        setCurrentData(prevData);
        
    }
    const sectionByCategory = (category:string) =>{
        if(typeof(category) === "undefined")
            return;
        let _currentCategory = allCategories.find(item => item.id === category);
        return sections.find(item => item.id === _currentCategory?.section)?.id;
    }
    const onChangeCaseSection = (sectionId:string) => {
        if(typeof(sectionId) === "undefined")
            return;
        
        let updatedCurrentData = currentData;
        let currentCategories = allCategories.filter(item => item?.section === sectionId);
        
        if(currentCategories.some(item => item.id === currentData.category)){
            updatedCurrentData.category = currentData.category;
        } else {
            updatedCurrentData.category = currentCategories[0].id;
        }
        
        setCaseCategories(currentCategories);
        
    }
    const addAnswer = (question:Question) => {
        let prevData = {...currentData};
        let index = (question.answers?.length ?? 0) + 1;
        
        for (let i = 0; i < prevData.quest.questions?.length; i++)
            if(prevData.quest.questions[i].question === question.question){
                prevData.quest.questions[i].answers.push({ es:`Respuesta ${index}`, en:`Answer ${index}` });
                break;
            }

        setCurrentData(prevData);
    }
    const removeAnswer = (answer:Answer, question:Question) => {
        let prevData = { ...currentData };
        const indexQuestion = prevData.quest.questions.findIndex((x:any) => x.question === question.question);

        if(indexQuestion !== -1)
            prevData.quest.questions[indexQuestion].answers = prevData.quest.questions[indexQuestion].answers.filter((x:any) => x.es !== answer.es);        
        
        setCurrentData(prevData);
    }
    const handleAnswerCurrentData = (question:Question, answer:Answer, value:any) => {
        let prevData = {...currentData};
        
        prevData.quest.questions.forEach((q:any) => {
            if(q.question === question.question)
                q.answers.forEach((a:Answer|any) => {
                    if(a.es === answer.es)
                        a[Lang()] = value;
                });
        });
        setCurrentData(prevData);
        
    }
    const handleRightAnswer = (question:Question, value:number) => {
        let prevData = {...currentData};

        prevData.quest.questions.forEach((q:any) => {
            if(q.question === question.question){
                q.correct = value;
            }
        })
        setCurrentData(prevData)
    }
     //#endregion

    const Lang = () => localStorage.getItem("lan") ?? "en";
    
    const translate = async() => {
        let updatedData:CaseInterface|any = { ...currentData };
        const langs = translateLang === 'all' ? ["en", "ar", "fr", "pt", "ru", "zh-CN"] : [translateLang];
        setLoading(true);
        langs.forEach(async(l) => {
            updatedData.diagnostic[l] = await translateText(l, updatedData.diagnostic.es);
            updatedData.descriptionPreview[l] = await translateText(l, updatedData.descriptionPreview.es);
            updatedData.description[l] = await translateText(l, updatedData.description.es);
            updatedData.title[l] = await translateText(l, updatedData.title.es);
            updatedData.hiddenTitle[l] = await translateText(l, updatedData.hiddenTitle.es);

            updatedData.quest.questions.forEach(async(value:any, index:number) => {
                updatedData.quest.questions[index].question[l] = await translateText(l, value.question.es);
                updatedData.quest.questions[index].explanation[l] = await translateText(l, value.explanation.es);
                value.answers.forEach(async(value2:any, index2:number) => { updatedData.quest.questions[index].answers[index2][l] = await translateText(l, value2.es); });
            })

            updatedData.views.forEach(async(value:any, index:number) => {
                updatedData.views[index].title[l] = await translateText(l, value.title.es);
            });
        });

        setCurrentData(updatedData);
        setLoading(false);
        alert("Translation successfully. \nSubmit for save changes.");
    }
    const copyId = () => {
        navigator.clipboard.writeText(currentData.id).then(()=>{console.log("texto copiado al portapapeles")});
    }

    const translateText = async(l:string, text:string) => { 
        if(text === '')
            return '';
        var result = await Translator.TranslateAsync('es', l, text);
        return result;
    }
    const selectAsDemo=() => {
        axios.put(`${endpoint}/selectasdemo&${currentData?.id}`).then(()=>{
            alert("se selecciono este caso como \"demo\"")
        }).catch(err=>console.error(err));
    }

    useEffect(()=>{ChangeElement(window.location.hash.substring(1));}, [cases]);
    useEffect(()=> { onLoadPage(); }, []);

    if(loading) return <Loading/>
    return (
        <main className="cases">
            {
                //#region Basic 
            }
            <label>{"Select Section"}</label>
            <select name="section-filter" onChange={onChangeSectionFilter}>
                <option key="all" value = "all">{t("Cases.All")}</option>
                {
                    sections?.map((x:any) => ( <option key={x.id} value={x.id}>{x?.title[Lang()] ?? `${x?.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)` ?? ""}</option> ))
                }
            </select>
            <label>{t("Cases.SelectCategory")}</label>
            <select name="category-filter" value={currentCategory} onChange={(e)=>{onChangeCategoryFilter(e.target.value)}}>
                <option key="all" value = "all">{t("Cases.All")}</option>
                {
                    categories?.map((x:any) => ( <option key={x.id} value={x.id}>{x?.title[Lang()] ?? `${x?.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)` ?? ""}</option>))
                }
            </select>
            <label>{t("Cases.SelectCase")}</label>
            <select name="cases" id="cases" value={currentData?.id} onChange={(e) => ChangeElement(e.target.value)}  >
                {
                    cases?.map((x:any) => ( <option key={x.id} value={x.id}>{x.title[Lang()] ?? `${x?.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)` ?? ""}</option> ))
                }
            </select>

            <div className="justify-start">
                <div className="large-button bg-blue" style={{width:200, height:25}} onClick={addNewCase}>{t("Cases.AddCase")}</div>
                <div className="large-button bg-red m-h-10" style={{width:200, height:25}} onClick={deleteCase}>{t("Cases.DeleteCase")}</div>
                <div className="large-button bg-blue" style={{width:200, height:25}} onClick={duplicateCase}>{t("Cases.DuplicateCase")}</div>
                
                <div className="m-h-10 center">
                    <span>{t("Cases.Language")}</span> 
                    <select className="input m-h-10" name="languages" id="languages" onChange={(e)=>{ setTranslateLang(e.target.value)}}>
                        <option value="all">{t("Cases.All")}</option>    
                        <option value="es">Español</option>
                        <option value="en">English</option>
                        <option value="fr">Français</option>
                        <option value="ar">عرب</option>
                        <option value="pt">Português</option>
                        <option value="zh-CN">中国人</option>
                        <option value="ru">Русский</option>
                    </select>
                </div>
                <div className='large-button bg-blue translate-container' style={{width:25, height:25}} onClick={translate} >
                    <img className="translate-img" src={ process.env.PUBLIC_URL + "/Translate.png"}/>
                </div>
            </div>
            
            <div className="row">
                <span>{t("Cases.CourseStatus") + " "}<span className={currentData?.visibility ? "visible" : "invisible"}>{ currentData?.visibility ? t("Cases.Visible") : t("Cases.Hidden")}</span></span>
                <div>
                    <span>{t("Cases.CourseId") + " "}</span>
                    <a className="openCase-link" onClick={ ()=> { /*setIsOpenSelectSimulatorModal(true)*/ OpenCase(user?.uid ?? "", currentData?.id); }} >{currentData?.id}</a>
                    <img className="copy-icon" src={process.env.PUBLIC_URL + "/copy_icon.png"} onClick={copyId} />
                </div>
            </div>
            <h2>{t("Cases.InformationAndAttributes")}</h2>
            <div className="grid-2">
                <div className="content justify-center">
                    <div className="data"> <span>{t("Cases.Title")} </span> <input className="input" type="text" value={ currentData?.title[Lang()] ?? `${currentData?.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)`  ?? ""}  name="title" onChange={(e)=> {handleChangeMulti(e.target.name, e.target.value)}} /> </div>
                    <div className="data">
                        <span>{t("Categories.Section")}</span>
                        <select className="input" name="sections" id="sections" value={sectionByCategory(currentData?.category) } onChange={(e) => { onChangeCaseSection(e.target.value) }}>
                            {
                                caseSections.map((x:any) => (
                                    <option key={x.id} value={x.id}>{x.title[Lang()] ?? `${x.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)`  ?? ""}</option>
                                ))
                            }
                        </select>
                        </div>
                    <div className="data"> 
                        <span>{t("Cases.Category")}</span> 
                        <select className="input" name="category" id="categories" value={ currentData?.category } onChange={(e) => { handleCurrentData(e.target.name, e.target.value) }}>
                            {
                                caseCategories.map((x:any) => (
                                    <option key={x.id} value={x.id}>{x.title[Lang()] ?? `${x.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)`  ?? ""}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="data"> <span>{t("Cases.HiddenTitle")}</span> <input className="input" type="text" name="hiddenTitle" value={ currentData?.hiddenTitle[Lang()] ?? `${currentData?.hiddenTitle["es"]} (not translated into '${Lang().toUpperCase()}' yet)` ?? "" } onChange={(e)=> {handleChangeMulti(e.target.name, e.target.value)}} /> </div>
                    <div className="data"> <span>{t("Cases.ShortDescription")}</span> <input className="input" type="text" name="descriptionPreview" value={ currentData?.descriptionPreview[Lang()] ?? `${currentData?.descriptionPreview["es"]} (not translated into '${Lang().toUpperCase()}' yet)` ?? "" } onChange={(e)=> {handleChangeMulti(e.target.name, e.target.value)}}  /> </div>
                </div>
                <div className="content justify-center">
                <span>{t("Cases.Description")}</span>
                <textarea className="input input-large" name="description" value={currentData?.description[Lang()] ?? `${currentData?.description["es"]} (not translated into '${Lang().toUpperCase()}' yet)` ?? ""} onChange={(e)=> {handleChangeMulti(e.target.name, e.target.value)}}  />
                    
                    <div className="large-button bg-blue" onClick={ selectAsDemo }>{ t("Cases.SelectAsDemo")}</div>
                    <div className="large-button bg-blue" onClick={()=> { handleCurrentData("visibility", !currentData?.visibility)}}>{ t(currentData?.visibility ?"Cases.Hide" : "Cases.Show")}</div>
            </div>
            </div>
            
            {    
                 //#endregion
            }
            {
                //#region Views 
            }
            <h2>{t("Cases.Views.Views")}</h2>
            {
                currentData?.views?.map((x:any, index:number) => (
                    <div className="content cases-column views position-relative">
                        <img className="close-icon" src={process.env.PUBLIC_URL + "/x-icon.png"} onClick={()=> { removeView(x) }} />
                        <h3>{ `${t("Cases.Views.View")} ${(index + 1)}` }</h3>
                        <div className="row justify-between no-margin"> 
                            <span>{t("Cases.Views.Status")} <span className={x?.visibility ? "visible" : "invisible"}>{ x?.visibility ? t("Cases.Visible") : t("Cases.Views.Hide")}</span></span> 
                        </div>
                        <span>{t("Cases.Views.Title")}</span>
                        <input className="input data" type="text" name="title" value={x.title[Lang()] ?? `${ x.title["es"]} (not translated into '${Lang().toUpperCase()}' yet)` ?? "" } onChange={(e)=> {handleViewCurrentData(x, e.target.name, e.target.value, true)}} />
                            
                        
                        <div className="grid-2">
                            <div className="cases-column">
                                <div className="data justify-start">
                                    <span>Transductor:</span>
                                    <select name="transducerType" value={x?.transducerType ?? 0}  onChange={(e) => handleViewCurrentData(x, e.target.name, e.target.value)}>
                                        <option key={0} value={0}>Abdominal</option>
                                        <option key={1} value={1}>Transvaginal</option>
                                        <option key={2} value={2}>Volumétrico</option>
                                        <option key={3} value={3}>Volumétrico v2</option>
                                    </select>
                                </div>
                                <div className="data justify-between">
                                    <div className="data"><span>{t("Cases.Views.Width")}</span> <input className="input" type="number" name="width" value={x.width ?? ""} onChange={(e)=> { handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/> </div>
                                    <div className="data"><span>{t("Cases.Views.Height")}</span> <input className="input" type="number" name="height" value={x.height ?? ""} onChange={(e)=> { handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/> </div>
                                </div>
                                
                                <div className="justify-between">
                                    <div >
                                        <span>{t("Cases.Views.CurveType")}</span>
                                        <select name="curveType" value={x?.curveType ?? 0} onChange={(e)=>{handleViewCurrentData(x, e.target.name, e.target.value); resetNormallity(x, "") }}>
                                            {
                                                curveOptions.map((value)=> (
                                                    <option key={value.value} value={value.value}>{value.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <span>{t("Cases.Views.Normallity")}</span> 
                                        <select name="normallity" disabled = { typeof(x?.curveType) === 'undefined' || x?.curveType == 0} value={x?.normallity ?? 0} onChange={(e) => { handleViewCurrentData(x, e.target.name, e.target.value)}}>
                                                <option key={0} value={0}>N/A</option>
                                            {
                                                x?.curveType > 0 && curveCategories[(x?.curveType ?? 1) - 1].Subcategories.map((value, i)=> (<option key={i + 1} value={i + 1}>{value}</option>))
                                            }
                                        </select>
                                    </div> 
                                    <div>
                                        <span>PS:</span> 
                                        <input className="input" type="number" name="PS" disabled = { typeof(x?.curveType) === 'undefined' || typeof(x?.normallity) === 'undefined' || x?.normallity == 0 } value={typeof(x?.curveType) === 'undefined' || typeof(x?.normallity) === 'undefined' || x?.normallity == 0 ? 0 : x.PS ?? "0"} onChange={(e) =>{ handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/>
                                    </div>
                                     <div>
                                        <span>ED:</span>
                                        <input className="input" type="number" name="ED" disabled = { enabledFields(x?.curveType, x?.normallity) } value={ enabledFields(x?.curveType, x?.normallity) ? 0 : x.ED ?? "0"} onChange={(e) =>{ handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/>
                                     </div>
                                </div>
                                <div className="justify-between">
                                    <div>
                                        <span>{t("Cases.Views.CurveType")}</span>
                                        <select name="curveType2" value={x?.curveType2 ?? 0} onChange={(e)=>{handleViewCurrentData(x, e.target.name, e.target.value); resetNormallity(x, "2")}}>
                                            {
                                                curveOptions.map((value)=> (<option key={value.value} value={value.value}>{value.label}</option>))
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <span>{t("Cases.Views.Normallity")}</span> 
                                        <select name="normallity2" disabled = { typeof(x?.curveType2) === 'undefined' || x?.curveType2 == 0} value={x?.normallity2 ?? 0} onChange={(e)=>{handleViewCurrentData(x, e.target.name, e.target.value)}}>
                                            <option key={0} value={0}>N/A</option>
                                            {
                                                x?.curveType2 > 0 && curveCategories[(x?.curveType2 ?? 1) - 1].Subcategories.map((value, i)=> (<option key={i + 1} value={i + 1}>{value}</option>))
                                            }
                                        </select>
                                    </div> 
                                    <div>
                                        <span>PS:</span> 
                                        <input className="input" type="number" name="PS2" disabled = { enabledFields(x?.curveType2, x?.normallity2) } value={enabledFields(x?.curveType2, x?.normallity2) ? 0 : x.PS2 ?? "0"} onChange={(e) =>{ handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/>
                                    </div>
                                    <div>
                                        <span>ED:</span>
                                        <input className="input" type="number" name="ED2" disabled = { enabledFields(x?.curveType2, x?.normallity2) } value={ enabledFields(x?.curveType2, x?.normallity2) ? 0 : x.ED2 ?? "0"}  onChange={(e) =>{ handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/>
                                    </div>
                                </div>
                                <span>FC:</span> <input className="input" type="number" name="FC" value={x.FC ?? "0"} onChange={(e) =>{ handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/>
                            </div>
                            <div className="cases-column">
                                <div className="data bold justify-between"> 
                                    <div className="row"> 
                                        <span>{t("Cases.Views.UnityKey")}</span> 
                                        <input className="input" type="text" name="unityKey" value={x.unityKey ?? ""} onChange={(e)=> { handleViewCurrentData(x, e.target.name, e.target.value) }}/> 
                                    </div>
                                    <div className="row"> 
                                    <span>{t("Area %")}</span> 
                                        <input className="input" type="text" disabled value={x.noDopplerPercent ?? 'undefined'} />
                                    </div>
                                </div>

                                <div className="data justify-between"> 
                                    <div className="data">
                                        <span>{t("Cases.Views.Fragments")}(Frames)</span> 
                                        <input className="input" type="number" name="fragments" value={x.fragments ?? ""} onChange={(e)=> { handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }} /> 
                                    </div>
                                    <div className="data"> 
                                        <span>Frame Rate:</span> 
                                        <input className="input" type="number" name="frameRate" value={x.frameRate ?? "1"} onChange={(e) =>{ handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/> 
                                    </div>
                                </div>
                                
                                <div className="data bold justify-between"> 
                                    <div className="row">
                                        <span>{t("Cases.Views.DopplerKey")}</span> 
                                        <input className="input" type="text" name="dopplerKey" value={x.dopplerKey ?? ""} onChange={(e)=> { handleViewCurrentData(x, e.target.name, e.target.value) }}/> 
                                    </div>
                                    <div className="row">
                                        <span>{"Area %"}</span> 
                                        <input className="input" type="text" disabled value={x.dopplerPercent ?? 'undefined'} />
                                    </div>
                                </div>
                                
                                <div className="data justify-between"> 
                                    <div className="data">
                                        <span>{t("Cases.Views.Fragments")}(Frames)</span> 
                                        <input className="input" type="number" name="DopplerFragments" value={x.DopplerFragments ?? ""} onChange={(e)=> { handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }} /> 
                                    </div>
                                    <div className="data"> 
                                        <span>Frame Rate:</span> 
                                        <input className="input" type="number" name="DopplerFrameRate" value={x.DopplerFrameRate ?? "1"} onChange={(e) =>{ handleViewCurrentData(x, e.target.name, e.target.valueAsNumber) }}/> 
                                    </div>
                                </div>
                                <div className="large-button bg-blue" onClick={(e)=> {handleViewCurrentData(x, "visibility", !x?.visibility)}}>{ !x?.visibility ? t("Cases.Views.Publish") : t("Cases.Views.Hide")}</div>
                            </div>
                        </div>
                        
                    </div>
                ))
            }
            <div className="large-button bg-white" onClick={addNewView}>{t("Cases.AddView")}</div>
            {
                 //#endregion
            }
            {
                //#region Questions 
            }
            <h2>{t("Cases.Questions")}</h2>
            { 
                currentData?.quest.questions?.length > 0 && <div>
                    {
                        currentData.quest.questions.map((x:any, index:number) => (
                            <div className="content m-10 position-relative"> 
                                <img className="close-icon" src={process.env.PUBLIC_URL + "/x-icon.png"} onClick={() => {removeQuestion(x)}} />
                                <h4 className="m-v-10">{t("Cases.Question") + (index + 1) }</h4>
                                <div className="row justify-between"> 
                                    <span className="m-h-10">{t("Cases.Question")}</span> 
                                    <input className="input width-100" type="text" name='question' value={x.question[Lang()] ?? `${x.question["es"]} (not translated into '${Lang()} yet')` } onChange={(e => {handleQuestionCurrentData(x, e.target.name, e.target.value)})} />
                                </div>
                                
                                <br />
                                <div className="column data"> 
                                    <span className="m-v-10">{t("Cases.Explanation")}</span>
                                    <textarea className="input input-large width-100" name="explanation" value={ typeof(x.explanation) === 'undefined' ? "" : x?.explanation[Lang()] ?? `${x?.explanation["es"]} (not translated into '${Lang()} yet')` } onChange={(e => {handleQuestionCurrentData(x, e.target.name, e.target.value)})} style={{height:120}} />
                                </div>
                                <br />
                                <span className="m-v-10">{x.answers.length > 0 ? t("Cases.Answers") : ""}</span>
                                <div id="answers">
                                    {
                                        x.answers.map((y:any, index:number) => (
                                            <div className="row position-relative">
                                                <input className="m-10" type="radio" id="" name="" checked={x.correct === index} onChange={(e) => {handleRightAnswer(x, index)}} />
                                                <input className="input width-100 m-v-10" name="answer" value={ y[Lang()] ?? `${y["es"]} (not translated into '${Lang()} yet')`} type="text" onChange={(e)=> {handleAnswerCurrentData(x, y, e.target.value)}} />
                                                <img className="close-icon-2" src={process.env.PUBLIC_URL + "/x-icon.png"} alt="" onClick={()=>{removeAnswer(y, x)}}/>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="large-button bg-white" onClick={ () => { addAnswer(x) } }>{t("Cases.AddAnswer")}</div>
                            </div>
                        ))
                    } 
                </div> 
            }
            <div className="large-button bg-white" onClick={addQuestion}> {t("Cases.AddQuestion")}</div>
            {
                 //#endregion
            }

            <div className="large-button bg-blue floating-btn" onClick={updateCase}>{t("Cases.Submit")}</div>
            
            <SimulatorModal _case = {currentData?.id} lang={Lang()} userId={user?.uid} isOpenSelectSimulatorModal = {isOpenSelectSimulatorModal} onCloseModal={()=>{setIsOpenSelectSimulatorModal(false)}}/>
        </main>
    );
}